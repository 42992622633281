/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, isEmpty } from 'lodash'
import React from 'react'

import { customSendbackMessage } from '../../components/feature-flags'
import * as Icons from '../../icons'
import ConfigBox from '../../ui/config-box'
import * as ConsensusPicker from '../components/consensus-picker'
import * as CustomTitle from '../components/custom-title'
import * as Denial from '../components/denial'
import * as EmailBuilder from '../components/email-builder'
import * as Instructions from '../components/instructions'
import * as PersonPicker from '../components/person-picker'
import * as ReminderSettings from '../components/reminder-settings'
import * as SectionSettings from '../components/section-settings'
import {
  SimulateButtons,
  SimulateHeader,
  SimulateWrapper
} from '../components/simulator-parts'
import ActionEmail from '../components/simulator-parts/action-email'
import SimulationContext from '../components/simulator-parts/context'
import SimulationError from '../components/simulator-parts/error-page'
import SimulationFormEditor from '../components/simulator-parts/form-editor'
import SendbackEmail from '../components/simulator-parts/sb-email'
import SendbackButton from '../components/simulator-parts/sendback-button'

const Node = ({ details, errors, Flowbot, fbProps }) => (
  <>
    <PersonPicker.View details={details} errors={errors} />
    <Instructions.View errors={errors} />
    {details.customNotification?.enabled && (
      <EmailBuilder.View errors={errors} />
    )}
    {details.customSendbackNotification?.enabled && (
      <EmailBuilder.View errors={errors} prefix='sendback-' />
    )}
  </>
)

const Config = ({
  appId,
  value,
  updateValue,
  errors,
  fieldsMultiUsers,
  fieldsUsers,
  fieldsGroups,
  fieldsAll,
  formSections,
  workflowSettings
}) => {
  const updateNotification = fn => {
    updateValue(draft => {
      const customNotification = draft.customNotification || {}
      fn(customNotification)
      draft.customNotification = customNotification
    })
  }
  const updateSendbackNotification = fn => {
    updateValue(draft => {
      const customSendbackNotification = draft.customSendbackNotification || {}
      fn(customSendbackNotification)
      draft.customSendbackNotification = customSendbackNotification
    })
  }
  const sendbackEnabled = !(
    value.disableSendback ?? workflowSettings.disableSendback
  )
  const historyEnabled = !(
    value.disableDocumentHistory ?? workflowSettings.disableDocumentHistory
  )
  return (
    <>
      <CustomTitle.Config
        value={value}
        updateValue={updateValue}
        defaultName='Task'
        label={i18n._('step.label')}
      />
      <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
      <PersonPicker.Config
        value={value}
        updateValue={updateValue}
        errors={errors}
        fieldsMultiUsers={fieldsMultiUsers}
        fieldsUsers={fieldsUsers}
        fieldsGroups={fieldsGroups}
        title={i18n._('who.needs.complete.step')}
      />
      <div className='h-4' />
      <Instructions.Config
        required
        errors={errors}
        value={value}
        updateValue={updateValue}
      />
      <div className='w-full border-b border-light-gray-400 pb-4 dark:border-light-gray-300' />
      <EmailBuilder.Config
        toggle
        value={value?.customNotification || {}}
        updateValue={updateNotification}
        errors={errors}
        fieldsAll={fieldsAll}
        defaultSubject='Task:'
      />
      <SectionSettings.Config
        appId={appId}
        defaultFormToViewOnly={workflowSettings.defaultFormToViewOnly}
        formSections={formSections}
        value={value}
        updateValue={updateValue}
      />
      <ReminderSettings.Config
        updateValue={updateValue}
        value={value}
        description={
          <div>
            <Trans id='enable.workflow.reminder.email.notifications' />
          </div>
        }
      />
      <ConfigBox
        label={
          workflowSettings.disableSendback
            ? i18n._('pagesbuilder.workflow.step.override.sendback.label')
            : i18n._('pagesbuilder.workflow.step.disable.sendback.label')
        }
        enabled={
          workflowSettings.disableSendback ? sendbackEnabled : !sendbackEnabled
        }
        save={() => () =>
          updateValue(draft => {
            draft.disableSendback = !(
              draft.disableSendback ?? workflowSettings.disableSendback
            )
          })
        }
      />
      {!workflowSettings.disableSendback &&
        sendbackEnabled &&
        customSendbackMessage && (
          <EmailBuilder.Config
            toggle
            value={value?.customSendbackNotification || {}}
            updateValue={updateSendbackNotification}
            errors={errors}
            fieldsAll={[
              ...fieldsAll,
              {
                id: 'workflow.sendbackComment',
                formKey: 'workflow.sendbackComment',
                type: 'WorkflowComment',
                label: 'Sendback Comment'
              }
            ]}
            defaultSubject={i18n._(
              'pagesbuilder.workflow.step.subject.sendback'
            )}
            label={i18n._('customize.sendback.email.notification')}
            prefix='sendback-'
            helpText={
              <Trans id='setting.enables.custom.message.in.sendback.notification' />
            }
          />
        )}
      <ConfigBox
        label={
          workflowSettings.disableDocumentHistory
            ? i18n._('pagesbuilder.workflow.step.override.history.label')
            : i18n._('pagesbuilder.workflow.step.disable.history.label')
        }
        enabled={
          workflowSettings.disableDocumentHistory
            ? historyEnabled
            : !historyEnabled
        }
        save={() => () =>
          updateValue(draft => {
            draft.disableDocumentHistory = !(
              draft.disableDocumentHistory ??
              workflowSettings.disableDocumentHistory
            )
          })
        }
      />
    </>
  )
}

const Simulate = ({
  appName,
  branding,
  form,
  headless,
  imageCache,
  simulateAction,
  simulationState,
  updateImageCache,
  value,
  workflow,
  workflowSettings
}) => {
  const { doc, handleChange, metaFields, template, usedFields, validations } =
    React.useContext(SimulationContext)
  const { assignees, instructions, status } = value
  const [currentUser, setCurrentUser] = React.useState()
  if (!currentUser && assignees.length) {
    setCurrentUser(assignees[0])
  }
  const changeUser = newUser => setCurrentUser(newUser.user)
  const stepDefinition = find(workflow?.steps, { _id: value.stepDefinitionId })
  if (value.isSendback) {
    return (
      <SimulateWrapper
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <SimulateHeader
          selectedUser={currentUser}
          onCurrentUserChange={changeUser}
          userOptions={assignees}
        />
        <SendbackEmail
          appName={appName}
          branding={branding}
          currentUser={currentUser}
          customNotification={value.customNotification}
          onClick={() => simulateAction('next', simulationState)}
          simulationState={simulationState}
        />
      </SimulateWrapper>
    )
  } else if (status === 'Notified') {
    return (
      <SimulateWrapper
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
      >
        <SimulateHeader
          selectedUser={currentUser}
          onCurrentUserChange={changeUser}
          userOptions={assignees}
        />
        <ActionEmail
          actionType='Complete Task'
          appName={appName}
          branding={branding}
          buttonText='View Task'
          currentUser={currentUser}
          customNotification={value.customNotification}
          defaultSubject={`You have been assigned a TASK: ${appName} - ${value.stepName}`}
          imageCache={imageCache}
          instructions={instructions}
          stepName={value.stepName}
          onClick={() => simulateAction('next', simulationState)}
          simulationState={simulationState}
        />
      </SimulateWrapper>
    )
  } else if (status === 'Error') {
    return (
      <SimulationError
        form={form}
        headless={headless}
        simulateAction={simulateAction}
        simulationState={simulationState}
        value={value}
      />
    )
  }

  const showSendbackButton = !(
    stepDefinition?.disableSendback ?? workflowSettings.disableSendback
  )
  const buttons = (
    <SimulateButtons>
      <div>
        <button
          className='kp-button-outline'
          disabled={simulationState.processing}
          id='back-btn'
          onClick={() => simulateAction('back', simulationState)}
        >
          <Trans id='back' />
        </button>
      </div>
      <div className='flex'>
        {showSendbackButton && (
          <SendbackButton
            currentUser={currentUser}
            simulateAction={simulateAction}
            simulationState={simulationState}
            validations={validations}
            value={value}
          />
        )}
        <button
          className='kp-button-solid'
          id='default-btn'
          disabled={!isEmpty(validations) || simulationState.processing}
          onClick={() =>
            simulateAction('complete', simulationState, {
              completedBy: currentUser
            })
          }
        >
          <Trans id='mark.complete' />
        </button>
      </div>
    </SimulateButtons>
  )
  return (
    <SimulateWrapper
      buttons={buttons}
      headless={headless}
      simulateAction={simulateAction}
      simulationState={simulationState}
    >
      <SimulateHeader
        selectedUser={currentUser}
        onCurrentUserChange={changeUser}
        userOptions={assignees}
      />
      <SimulationFormEditor
        currentUser={currentUser}
        doc={doc}
        handleChange={handleChange}
        imageCache={imageCache}
        instructions={instructions}
        labelSize={form.labelSize}
        metaFields={metaFields}
        template={template}
        updateImageCache={updateImageCache}
        usedFields={usedFields}
        validations={validations}
      />
    </SimulateWrapper>
  )
}

export default {
  name: 'Task',
  Icon: Icons.Point,
  color: '#468DCB',
  validate: (value, fieldsAll, lineage) => {
    let validations = [
      ...PersonPicker.validate(value, fieldsAll),
      ...Instructions.validate(value)
    ]
    if (value?.customNotification?.enabled) {
      const more = EmailBuilder.validate(
        value?.customNotification,
        fieldsAll,
        lineage
      )
      validations = validations.concat(more)
    }
    if (value?.customSendbackNotification?.enabled) {
      const more = EmailBuilder.validate(
        value?.customSendbackNotification,
        [
          ...fieldsAll,
          {
            id: 'workflow.sendbackComment',
            formKey: 'workflow.sendbackComment',
            type: 'WorkflowComment',
            label: 'Sendback Comment'
          }
        ],
        lineage,
        'sendback-',
        'Send back '
      )
      validations = validations.concat(more)
    }
    return validations
  },
  defaultTemplate: () => ({
    subflows: [{ steps: [] }],
    ...PersonPicker.defaults(),
    ...ConsensusPicker.defaults(),
    ...Denial.defaults(),
    customNotification: { ...EmailBuilder.defaults(), enabled: false }
  }),
  canHaveEditPerms: true,
  Config,
  Node,
  Simulate
}
