/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { startOfDay, sub } from 'date-fns'
import { find, map } from 'lodash'
import React from 'react'

import { DatePicker } from '../../../components/temporal-pickers'
import { VisuallyHidden } from '../../../ui/a11y'
import { Option, Select } from '../../../ui/select'
import { getDateObject } from './util'

const getDate = fn => +fn(startOfDay(new Date()))

const timeFilters = [
  {
    key: 'all.time',
    label: <Trans id='all.time' />,
    value: ''
  },
  {
    key: 'today',
    label: <Trans id='today' />,
    value: getDate(d => d)
  },
  {
    key: 'last.week',
    label: <Trans id='last.week' />,
    value: getDate(d => sub(d, { weeks: 1 }))
  },
  {
    key: 'last.month',
    label: <Trans id='last.month' />,
    value: getDate(d => sub(d, { months: 1 }))
  },
  {
    key: '3.months.ago',
    label: <Trans id='3.months.ago' />,
    value: getDate(d => sub(d, { months: 3 }))
  },
  {
    key: '6.months.ago',
    label: <Trans id='6.months.ago' />,
    value: getDate(d => sub(d, { months: 6 }))
  },
  {
    key: 'custom.range',
    label: <Trans id='custom.range' />,
    value: 'custom'
  }
]

const getSelected = ({ custom, min }) => {
  const actual = find(timeFilters, { value: min })
  if (actual && !custom) return '' + min
  if (!custom && !min) return ''
  return 'custom'
}

function DateInput ({ value, onChange }) {
  const handleChange = dateObject => onChange(dateObject.getTime())
  return <DatePicker onChange={handleChange} value={getDateObject(value)} />
}

export const UI = ({ gadget, onChange, value }) => {
  const selected = getSelected(value || {})
  return (
    <div>
      <Select
        value={selected}
        aria-label={i18n._('choose.date.range')}
        onChange={value => {
          if (!value) return onChange(null)
          const newFilter = { field: gadget.formKey, type: 'Date' }
          if (value === 'custom') newFilter.custom = true
          else newFilter.min = +value
          onChange(newFilter)
        }}
      >
        {map(timeFilters, time => (
          <Option key={time.key} value={time.value || ''}>
            {time.label}
          </Option>
        ))}
      </Select>
      {selected === 'custom' && (
        <>
          <div aria-hidden>
            <Trans id='from' />
          </div>
          <VisuallyHidden>
            <Trans id='select.start.date' />
          </VisuallyHidden>
          <DateInput
            value={value.min}
            onChange={date => onChange({ ...value, min: date })}
          />
          <div aria-hidden>
            {' '}
            <Trans id='to' />
          </div>
          <VisuallyHidden>
            <Trans id='select.end.date' />
          </VisuallyHidden>
          <DateInput
            value={value.max}
            onChange={date => onChange({ ...value, max: date })}
          />
        </>
      )}
    </div>
  )
}

const format = date =>
  getDateObject(+date).toLocaleDateString({
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })
const getPillValue = ({ custom, min, max }) => {
  const actual = find(timeFilters, { value: min })
  if (actual && !custom) return actual.label
  if (!custom && !min) return <Trans id='all.time' />
  if (min && max) return `${format(min)} - ${format(max)}`
  if (min) return `From ${format(min)}`
  if (max) return `To ${format(max)}`
  return <Trans id='custom.range' />
}

export const Pill = ({ label, filter, gadget }) => (
  <span>
    {label}: {getPillValue(filter || {})}
  </span>
)

const toString = val => (val ? '' + val : val)
export const toGraphQL = ({ field, min, max }) =>
  min || max
    ? [{ field, type: 'RANGE', min: toString(min), max: toString(max) }]
    : []

const toInt = val => (val ? +val : val)
export const fromGraphQL = ([gqlFilter]) => ({
  field: gqlFilter.field,
  type: 'Date',
  min: toInt(gqlFilter.min),
  max: toInt(gqlFilter.max)
})

export const newFromGraphQL = gqlFilters => {
  if (!(gqlFilters.min || gqlFilters.max)) return gqlFilters
  return {
    type: 'AND',
    operators: [
      gqlFilters.min && {
        field: gqlFilters.field,
        type: 'IS_ON_OR_AFTER',
        gadget: 'Date',
        value: gqlFilters.min
      },
      gqlFilters.max && {
        field: gqlFilters.field,
        type: 'IS_ON_OR_BEFORE',
        gadget: 'Date',
        value: gqlFilters.max
      }
    ].filter(Boolean)
  }
}

export const options = purpose => [
  { id: 'IS_EMPTY', label: i18n._({ id: 'is.empty', message: 'is empty' }) },
  {
    id: 'IS_NOT_EMPTY',
    label: i18n._({ id: 'is.not.empty', message: 'is not empty' })
  },
  {
    id: 'IS_BEFORE',
    label: i18n._({ id: 'is.before', message: 'is before' }),
    exposes: 'date'
  },
  {
    id: 'IS_ON_OR_BEFORE',
    label: i18n._({ id: 'is.on.or.before', message: 'is on or before' }),
    exposes: 'date'
  },
  {
    id: 'IS_AFTER',
    label: i18n._({ id: 'is.after', message: 'is after' }),
    exposes: 'date'
  },
  {
    id: 'IS_ON_OR_AFTER',
    label: i18n._({ id: 'is.on.or.after', message: 'is on or after' }),
    exposes: 'date'
  },
  { id: 'IS', label: i18n._('is'), exposes: 'date' },
  ...(!purpose || purpose === 'doc-list'
    ? [
        {
          id: 'relative',
          label: i18n._({ id: 'is.(relative)', message: 'is (relative)' }),
          exposes: 'single-select',
          options: [
            { id: 'today', label: i18n._({ id: 'today', message: 'Today' }) },
            {
              id: 'last.week',
              label: i18n._({ id: 'last.week', message: 'Last Week' })
            },
            {
              id: 'this.week',
              label: i18n._({ id: 'this.week', message: 'This Week' })
            },
            {
              id: 'last.month',
              label: i18n._({ id: 'last.month', message: 'Last Month' })
            },
            {
              id: 'this.month',
              label: i18n._({ id: 'this.month', message: 'This Month' })
            },
            {
              id: '3.months.ago',
              label: i18n._({ id: '3.months.ago', message: '3 Months Ago' })
            },
            {
              id: '6.months.ago',
              label: i18n._({ id: '6.months.ago', message: '6 Months Ago' })
            }
          ],
          transform: (filter, _formData) => {
            let after
            let before = `${find(timeFilters, { key: 'today' })?.value}`
            const today = new Date()
            const thisYear = today.getFullYear()
            const thisMonth = today.getMonth()
            if (filter.value === 'this.month') {
              after = `${getDate(() => new Date(thisYear, thisMonth, 0))}`
              before = `${getDate(() => new Date(thisYear, thisMonth + 1, 0))}`
            }
            if (filter.value === 'this.week') {
              const firstOfWeek = today.getDate() - today.getDay() - 1
              after = `${getDate(() => new Date(thisYear, thisMonth, firstOfWeek))}`
              before = `${getDate(() => new Date(thisYear, thisMonth, firstOfWeek + 7))}`
            }
            if (filter.value === 'today') {
              after = `${startOfDay(new Date(thisYear, thisMonth, today.getDate() - 1)).getTime()}`
            }
            if (!after) {
              after = `${find(timeFilters, { key: filter.value })?.value}`
            }
            return {
              operators: [
                {
                  field: filter.field,
                  type: 'IS_ON_OR_BEFORE',
                  value: before,
                  gadget: 'Date'
                },
                {
                  field: filter.field,
                  type: 'IS_ON_OR_AFTER',
                  value: after,
                  gadget: 'Date'
                }
              ],
              type: 'AND'
            }
          }
        }
      ]
    : [])
]
