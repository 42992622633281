/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import React from 'react'
import ReactDOM from 'react-dom'

import { ModalContext } from '../../../components/modal-page'

export function ModalFull ({ onClose, children }) {
  const depth = React.useContext(ModalContext)
  return ReactDOM.createPortal(
    <ModalContext.Provider value={depth + 1}>
      <div className='kp-modal'>
        <div
          className='kp-slide-up fixed left-0 w-screen'
          style={{
            top: depth * 30 + 10,
            height: `calc(100vh - ${depth * 30 + 10}px)`,
            zIndex: depth * 12
          }}
        >
          {/* the following style tag can be removed once firefox supports :has */}
          <style>{'body{overflow:hidden;}'}</style>
          {children}
        </div>
        <div
          onMouseUp={onClose}
          className='kp-fade fixed left-0 top-0 h-screen w-screen bg-black opacity-50'
          style={{ zIndex: depth * 11 }}
        />
      </div>
    </ModalContext.Provider>,
    document.body
  )
}

export function ModalCentered ({ onClose, children }) {
  return ReactDOM.createPortal(
    <div className='kp-modal fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center'>
      <div
        className='kp-fade absolute left-0 top-0 h-full w-full bg-black opacity-50'
        onMouseUp={onClose}
      />
      {children}
    </div>,
    document.body
  )
}
