/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { cloneDeep, reject } from 'lodash'
import React from 'react'
import { useParams } from 'react-router'

import PopoverButton from '../../../components/data-table/popover-button'
import { filtersOnDocList } from '../../../components/feature-flags'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import * as Icons from '../../../icons'
import * as Illustration from '../../../illustrations'
import { useAlerts } from '../../../ui/alerts'
import Checkbox from '../../../ui/checkbox'
import { useQueryContext } from './use-query-context'

export default function Views ({
  gqlParams,
  myFilters,
  setFilter,
  setToDefault,
  activeView,
  isAppAdmin
}) {
  const buttonProps = {
    transparent: true,
    small: true,
    ...(activeView === 'CUSTOM' && { className: '!bg-light-gray-200' }),
    ...(activeView !== 'DEFAULT' && { activeFilter: true })
  }

  return (
    <PopoverButton
      label={viewLabel}
      aria-labelledby='Views'
      aria-describedby='Views-list'
      buttonProps={buttonProps}
    >
      {() => (
        <div className='flex h-[400px] flex-col justify-between min-[501px]:w-80'>
          <SavedViews
            setToDefault={setToDefault}
            myFilters={myFilters.filter(f =>
              filtersOnDocList ? true : !f.node.adminView
            )}
            setFilter={setFilter}
            activeView={activeView}
            isAppAdmin={isAppAdmin}
          />
          <SaveButton
            params={gqlParams}
            setFilter={setFilter}
            isAppAdmin={isAppAdmin}
          />
        </div>
      )}
    </PopoverButton>
  )
}

function SaveButton ({ params, setFilter, isAppAdmin }) {
  const { appId, pageId, tableId } = useParams()
  const datasetId = tableId || pageId || appId
  const alerts = useAlerts()
  const [name, setName] = React.useState('')
  const [adminView, setAdminView] = React.useState(false)
  const saveFilter = useSaveFilterMutation(datasetId, name, adminView, params)
  const save = async e => {
    e.preventDefault()
    const result = await saveFilter()
    setFilter(result.data.saveFilter.connection, result.data.saveFilter.id)
    setName('')
    alerts.type3(
      i18n._({
        id: 'pagesbuilder.doclist.view.filter.saved',
        message: 'Filter Saved'
      }),
      'success'
    )
  }
  return (
    <form
      className={cx(
        isAppAdmin && filtersOnDocList ? 'flex-col' : 'items-center',
        'flex gap-2 border-t border-light-gray-400 p-4'
      )}
      onSubmit={save}
    >
      <div className='sr-only'>Save your view</div>
      <input
        type='text'
        className={cx(
          { 'flex-1': !(isAppAdmin && filtersOnDocList) },
          'kp-input'
        )}
        placeholder={i18n._({
          id: 'pagesbuilder.doclist.view.name',
          message: 'Name current view'
        })}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      {isAppAdmin && filtersOnDocList ? (
        <div className='flex items-center justify-between'>
          <Checkbox
            label={
              <div className='flex items-center'>
                {i18n._({ id: 'Make.Public', message: 'Make Public' })}
                <TooltipTrigger
                  as={Icons.AlertHelp}
                  label={i18n._({
                    id: 'This.allows.all.other.users.in.this.app.to.use.this.filter',
                    message:
                      'This allows all other users in this app to use this filter'
                  })}
                  ml={2}
                  tooltipId='allow-all-other-users-to-use-filter'
                />
                <Tooltip id='allow-all-other-users-to-use-filter'>
                  <div className='w-32'>
                    <Trans
                      id='This.allows.all.other.users.in.this.app.to.use.this.filter'
                      message='This allows all other users in this app to use this filter'
                    />
                  </div>
                </Tooltip>
              </div>
            }
            checked={adminView}
            onChange={() => setAdminView(!adminView)}
          />
          <button className='kp-button-outline' type='submit'>
            <Trans id='save' message='Save' />
          </button>
        </div>
      ) : (
        <button className='kp-button-outline' type='submit'>
          <Trans id='save' message='Save' />
        </button>
      )}
    </form>
  )
}

function SavedViews ({
  myFilters,
  setFilter,
  activeView,
  setToDefault,
  isAppAdmin
}) {
  return (
    <div className='max-h-[335px] w-full flex-1 overflow-y-auto'>
      {activeView === 'CUSTOM' && (
        <SavedViewItem
          label={i18n._({
            id: 'pagesbuilder.doclist.views.custom',
            message: 'Unsaved View'
          })}
          className='border-b border-light-gray-200 italic text-medium-gray-400'
          isActive
        />
      )}
      <SavedViewItem
        label={i18n._({
          id: 'pagesbuilder.doclist.views.default',
          message: 'Default View'
        })}
        onClick={setToDefault}
        isActive={activeView === 'DEFAULT'}
      />
      {myFilters.length ? (
        <div className='relative divide-y'>
          <div className='sr-only' id='Views-list'>
            <Trans
              id='pagesbuilder.doclist.views.saved.number'
              message='You have {length} saved Views.'
              values={{ length: myFilters.length }}
            />
          </div>
          {myFilters
            .map(({ node }) => (
              <SavedViewItem
                key={node.id}
                label={node.name}
                isActive={activeView === node.id}
                onClick={() => setFilter(node.connection, node.id)}
              >
                <div className='flex content-center items-center'>
                  {isAppAdmin && filtersOnDocList && (
                    <div className='mr-3'>
                      <TooltipTrigger
                        as={node.adminView ? Icons.Visible : Icons.Hidden}
                        label={i18n._(node.adminView ? 'Public' : 'Private')}
                        tooltipId={node.adminView ? 'Public' : 'Private'}
                      />
                      <Tooltip
                        id={node.adminView ? 'Public' : 'Private'}
                        place='bottom'
                      >
                        <div>
                          <Trans id={node.adminView ? 'Public' : 'Private'} />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {node.adminView && !isAppAdmin ? (
                    <div className='mr-6 text-nowrap text-xs text-dark-gray-500'>
                      {i18n._({
                        id: 'Shared.by.Admin',
                        message: 'Shared by Admin'
                      })}
                    </div>
                  ) : (
                    <DeleteButton
                      id={node.id}
                      label={node.name}
                      isActive={activeView === node.id}
                      setToDefault={setToDefault}
                    />
                  )}
                </div>
              </SavedViewItem>
            ))
            .reverse()}
        </div>
      ) : (
        <ViewInstructions />
      )}
    </div>
  )
}

const SavedViewItem = ({ label, isActive, onClick, children, className }) => {
  return (
    <div
      className={cx(
        'relative flex w-full items-center gap-2 border-light-gray-200 text-dark-gray-500 transition-all hover:bg-light-gray-100 active:text-dark-gray-300',
        {
          'bg-blue-100': isActive
        }
      )}
    >
      <button
        onClick={onClick}
        className={`flex min-h-11 w-full items-center gap-2 p-1 ${className}`}
      >
        <div className='flex h-8 w-8 items-center justify-center'>
          {isActive && <Icons.Check className='fill-blue-500' />}
        </div>
        <span className='flex flex-1 items-center text-left text-sm [word-break:break-word]'>
          {label}
        </span>
      </button>
      {children}
    </div>
  )
}

const DeleteButton = ({ isActive, id, label, setToDefault }) => {
  const alerts = useAlerts()
  const removeFilter = useRemoveFilterMutation(id)
  return (
    <button
      className='kp-button-transparent kp-button-icon mr-3 h-9 w-9 text-medium-gray-500'
      onClick={e => {
        e.stopPropagation()
        removeFilter()
        if (isActive) setToDefault()
        alerts.type3(
          i18n._({
            id: 'pagesbuilder.doclist.views.removed',
            message: 'Filter Removed'
          }),
          'success'
        )
      }}
    >
      <div className='sr-only'>{`Remove View: ${label}`}</div>
      <Icons.Delete />
    </button>
  )
}

const ViewInstructions = () => {
  return (
    <div className='border-t border-light-gray-400 p-4 text-sm'>
      <div className='flex'>
        <div className='relative'>
          <div className='text-sm font-medium text-medium-gray-500' id='Views'>
            <Trans
              id='pagesbuilder.doclist.views.what'
              message='WHAT ARE VIEWS?'
            />
          </div>
          <div id='Views-list' className='sr-only'>
            <Trans
              id='pagesbuilder.doclist.views.no.saved'
              message='You have no saved Views.'
            />
          </div>
          <div className='relative pb-4 pt-2'>
            <Trans
              id='pagesbuilder.doclist.views.save.time'
              message='Views save you time. Your column choices, sorting and searches can be saved for later as a custom view.'
            />
          </div>
        </div>
        <Illustration.Peter aria-hidden className='ml-4' />
      </div>
      <div className='text-sm font-medium text-medium-gray-500'>
        <Trans id='pagesbuilder.doclist.views.how' message='HOW TO USE THEM?' />
      </div>
      <ol className='list-decimal pl-4 pt-2'>
        <li>
          <Trans
            id='pagesbuilder.doclist.views.choose'
            message='Choose columns, sort and search.'
          />
        </li>
        <li>
          <Trans
            id='pagesbuilder.doclist.views.save.name'
            message='Save the view with a name below.'
          />
        </li>
        <li>
          <Trans
            id='pagesbuilder.doclist.views.appear'
            message='Your new view will appear here for you to use later.'
          />
        </li>
      </ol>
    </div>
  )
}

const viewLabel = (
  <>
    <Icons.Camera className='fill-blue-500' mr={2} />
    <Trans id='pagesbuilder.doclist.views' message='Views' />
  </>
)

const useRemoveFilterMutation = id => {
  const [removeFilter] = useMutation(deleteMutation)
  const query = useQueryContext()
  return () => removeFilter(getDeleteParams(id, query))
}

const deleteMutation = gql`
  mutation DeleteFilter($id: ID!) {
    deleteFilter(args: { id: $id })
  }
`

const getDeleteParams = (id, query) => ({
  variables: { id },
  optimisticResponse: {
    deleteFilter: 'Ok'
  },
  refetchQueries: [query],
  update: (store, { data: { deleteFilter } }) => {
    if (deleteFilter !== 'Ok') return
    const rawData = store.readQuery({ ...query })
    const data = cloneDeep(rawData)
    data.app.dataset.myFilters.edges = reject(
      data.app.dataset.myFilters.edges,
      e => e.node.id === id
    )
    store.writeQuery({ ...query, data })
  }
})

const useSaveFilterMutation = (appId, name, adminView = false, params) => {
  const [saveFilter] = useMutation(saveMutation)
  const query = useQueryContext()
  return () => saveFilter(getSaveParams(appId, name, query, adminView, params))
}

const saveMutation = gql`
  mutation SaveFilter(
    $appId: ID!
    $name: String!
    $sort: [String!]
    $fields: Operator
    $query: String
    $columns: [String!]!
    $adminView: Boolean
    $versionConfig: VersionConfig
  ) {
    saveFilter(
      args: {
        appId: $appId
        name: $name
        adminView: $adminView
        connection: {
          query: $query
          sort: $sort
          fields: $fields
          columns: $columns
          versionConfig: $versionConfig
        }
      }
    ) {
      id
      name
      connection {
        sort
        fields
        query
        columns
        versionConfig
      }
    }
  }
`

const getSaveParams = (
  appId,
  name,
  listPageQuery,
  adminView,
  { query, sort, fields, columns, versionConfig }
) => ({
  variables: {
    appId,
    name:
      name ||
      `${i18n._({ id: 'pagesbuilder.doclist.untitled.view', message: 'Untitled View' })}`,
    query,
    sort,
    fields,
    columns,
    adminView,
    versionConfig
  },
  refetchQueries: [listPageQuery]
})
